import { ProductChoiceType } from '@thg-commerce/enterprise-config'
import {
  useEnterpriseContext,
  useSiteConfig,
} from '@thg-commerce/enterprise-core'
import { useI18n } from '@thg-commerce/enterprise-core/src/i18n'
import {
  SelectedOptions,
  SubscriptionData,
} from '@thg-commerce/enterprise-network/src/ApolloProvider/resolvers/Query/Product/ProductPage'
import { PersonalisationData } from '@thg-commerce/enterprise-network/src/ApolloProvider/resolvers/Types/Product'
import {
  FulfilmentMethod,
  InStockLocation,
  SubscriptionContract,
} from '@thg-commerce/enterprise-network/src/generated/graphql'

import { SubscriptionPaymentType } from '../../ProductVariations'

export interface VariantFields {
  sku: string
  url?: string | null
  title: string
  images: {
    thumbnail?: string
    largeProduct?: string
    zoom?: string
    original?: string
  }[]
  choices: Choice[]
  maxPerOrder?: number | null
  price?: {
    price: {
      displayValue: string
      scalarValue: string
      amount: string
    }
    rrp: {
      displayValue: string
      scalarValue: string
      amount: string
    }
    saving?: string
  } | null
  availabilityMessage: string
  inStock: boolean
  product: { sku: number; url: string; linkedOn?: string }
  notifyWhenInStockEnabled?: boolean | null
  eligibleForFulfilmentMethods?: FulfilmentMethod[]
  subscriptionContracts?: SubscriptionContract[]
  isCheckStock?: boolean
  isOrderInStore?: boolean
  leadTime?: number
  weightGroups?: string[]
  isBookable?: boolean
  inStockLocations?: InStockLocation[]
  isSubscription?: boolean | null
  subscriptionPaymentType?: SubscriptionPaymentType
  subscriptionData?: SubscriptionData
  enableAddToBasket?: boolean
  personalisationData?: PersonalisationData
  extensions?: Record<string, any>
  personalisationFields?: any[]
}

export type Choice = {
  optionKey: string
  key: string
  colour?: string
  title: string
  disabled?: boolean
  customLabel?: boolean
}

export interface Option {
  key: string
  localizedKey?: string | null
  choices: Choice[]
}

export interface ProductOptionsTransformerProps<Variant extends VariantFields> {
  options: Option[]
  variants: Variant[]
  selectedVariant?: Variant
  variantChangedCallback: (variant: Variant) => void
  currentVariant?: Variant
  enabledSelectedOptionsFallback?: boolean
  optionKeysToFilter?: string[]
  defaultVariant?: Variant
  selectedOptions?: SelectedOptions
  selectedOptionsChangedCallback?: (selectedOptions: SelectedOptions) => void
}

// @deprecated - Requires complex client side logic. Use `productOptions` network transformer in your resolver instead
export const useProductOptionsTransformer = <Variant extends VariantFields>(
  useDropdowns?: boolean,
) => {
  const { appConfig } = useEnterpriseContext()
  const { enablePreselectedProductVariant } = useSiteConfig()

  const i18n = useI18n()

  const i18nText = {
    selectPlaceholder: i18n('general.variations.options.placeholder.text'),
    productOptions: {
      swatch: {
        unavailableText: i18n(
          'product.productoptions.dropdown.unavailable.text',
        ),
        closeButtonText: i18n(
          'product.productoptions.swatch.tooltip.close.text',
        ),
        showMoreButtonText: i18n(
          'product.productoptions.swatch.button.more.text',
        ),
        showLessButtonText: i18n(
          'product.productoptions.swatch.button.less.text',
        ),
      },
      imageSwatch: {
        showButtonText: i18n(
          'product.productoptions.imageswatch.button.show.text',
        ),
        showMoreButtonText: i18n(
          'product.productoptions.imageswatch.button.more.text',
        ),
        showLessButtonText: i18n(
          'product.productoptions.imageswatch.button.less.text',
        ),
      },
      dropdown: {
        unavailableText: i18n(
          'product.productoptions.dropdown.unavailable.text',
        ),
        customLabelText: i18n(
          'product.productoptions.dropdown.customlabel.text',
        ),
      },
      wishlist: {
        unavailableText: i18n('account.wishlist.option.unavailable.text'),
      },
    },
  }

  const getOptionsFromVariant = (variant?: Variant) => {
    const selectedOptions: SelectedOptions = {}
    variant?.choices?.map((choice) => {
      selectedOptions[choice.optionKey] = choice.key
    })

    return selectedOptions
  }

  return ({
    options,
    variants,
    selectedVariant,
    variantChangedCallback,
    currentVariant,
    enabledSelectedOptionsFallback,
    optionKeysToFilter,
    defaultVariant,
    selectedOptions,
    selectedOptionsChangedCallback,
  }: ProductOptionsTransformerProps<Variant>) => {
    const optionsChangedCallback = (
      selectedOptions: SelectedOptions,
      changedOptionKey: string,
    ) => {
      if (
        !enablePreselectedProductVariant &&
        Object.keys(selectedOptions).length !== variants[0].choices.length
      ) {
        return
      }
      const variantFromSelectedOptions = variants.find((variant) => {
        return (
          variant.choices.reduce((matchedChoices, choice) => {
            if (selectedOptions[choice.optionKey] === choice.key) {
              return matchedChoices + 1
            }
            return matchedChoices
          }, 0) === variant.choices.length ||
          Object.keys(selectedOptions).every((optionKey) =>
            variant.choices.some(
              (choice) => choice.key === selectedOptions[optionKey],
            ),
          )
        )
      })

      if (variantFromSelectedOptions) {
        variantChangedCallback(variantFromSelectedOptions)
        return
      }

      if (selectedOptions[changedOptionKey]) {
        const fallbackVariant = variants.find((variant) =>
          variant.choices.some(
            (choice) => choice.key === selectedOptions[changedOptionKey],
          ),
        )

        if (fallbackVariant) {
          variantChangedCallback(fallbackVariant)
        }
        return
      }
    }

    const onOptionChange = (optionKey?: string, value?: string) => {
      if (optionKey && value) {
        if (selectedOptions && selectedOptionsChangedCallback) {
          const newSelectedOptions = {
            ...selectedOptions,
            [optionKey]: value,
          }
          selectedOptionsChangedCallback(newSelectedOptions)
          optionsChangedCallback(newSelectedOptions, optionKey)
        } else {
          const currentSelectedOptions = getOptionsFromVariant(
            selectedVariant ||
              defaultVariant ||
              variants.find((variant) => variant.inStock),
          )

          const newSelectedOptions = {
            ...currentSelectedOptions,
            [optionKey]: value,
          }

          optionsChangedCallback(newSelectedOptions, optionKey)
        }
      }
    }

    const findVariantFromOptions = (
      selectedOptions: SelectedOptions,
      choicesToCheck: Choice[],
    ) => {
      return variants.find((variant) => {
        return (
          variant.choices.reduce((matchedChoices, choice) => {
            if (selectedOptions[choice.optionKey] === choice.key) {
              return matchedChoices + 1
            }
            return matchedChoices
          }, 0) === variant.choices.length ||
          choicesToCheck.every((choice) =>
            variant.choices.some((varChoice) => varChoice.key === choice.key),
          )
        )
      })
    }

    const shouldHideOption = (
      selectedVariant: {
        choices: Choice[]
        inStock: boolean
      },
      option: {
        key: string
        choices: Choice[]
      },
      choice: Choice,
    ) => {
      const otherVariantChoices = selectedVariant?.choices.filter(
        (item) => item.optionKey !== option.key,
      )
      const choicesToCheck = [...otherVariantChoices, choice]
      const selectedOptions: { [optionKey: string]: string } = {}
      choicesToCheck.forEach((choice) => {
        selectedOptions[choice.optionKey] = choice.key
      })

      const foundVariant = findVariantFromOptions(
        selectedOptions,
        choicesToCheck,
      )
      return (
        !foundVariant ||
        !foundVariant.choices.some((item) => item.key === choice.key)
      )
    }

    const isVariantInStockWithUpdatedChoices = (updatedChoices: Choice[]) => {
      const selectedOptions: { [optionKey: string]: string } = {}
      updatedChoices.forEach((choice) => {
        selectedOptions[choice.optionKey] = choice.key
      })
      const foundVariant = findVariantFromOptions(
        selectedOptions,
        updatedChoices,
      )
      return foundVariant && foundVariant.inStock
    }

    const updateSelectedChoicesWithChoice = (
      choice: Choice,
      selectedVariant?: Pick<Variant, 'inStock' | 'choices'>,
    ) => {
      return (
        selectedVariant?.choices.map((variantChoice) =>
          variantChoice.optionKey === choice.optionKey
            ? { ...choice }
            : { ...variantChoice },
        ) || [{ ...choice }]
      )
    }

    const transformOptionChoices = (
      option: {
        key: string
        choices: Choice[]
      },
      selectedVariant?: {
        choices: Choice[]
        inStock: boolean
        notifyWhenInStockEnabled?: boolean | null
      },
    ) => {
      return option.choices.reduce<Choice[]>((accumulator, choice) => {
        if (selectedVariant) {
          if (shouldHideOption(selectedVariant, option, choice)) {
            return accumulator
          }
        }

        if (!selectedVariant && options.length > 1) {
          accumulator.push({
            ...choice,
            disabled: false,
            customLabel: false,
          })

          return accumulator
        }

        const updatedChoices = updateSelectedChoicesWithChoice(
          choice,
          selectedVariant,
        )

        accumulator.push({
          ...choice,
          disabled: !isVariantInStockWithUpdatedChoices(updatedChoices),
          customLabel: selectedVariant?.notifyWhenInStockEnabled || undefined,
        })

        return accumulator
      }, [])
    }

    return {
      onOptionChange,
      selectedOptions: selectedVariant
        ? getOptionsFromVariant(selectedVariant)
        : enabledSelectedOptionsFallback
        ? getOptionsFromVariant(
            !currentVariant
              ? variants.find((variant) => variant.inStock)
              : currentVariant,
          )
        : undefined,
      i18nText: i18nText.productOptions,
      options: options.reduce<any[]>((accumulator, option) => {
        if (optionKeysToFilter?.includes(option.key)) {
          return accumulator
        }
        accumulator.push({
          type: useDropdowns
            ? ProductChoiceType.DROPDOWN
            : appConfig.productChoiceTypeMap?.[option.key].type ||
              ProductChoiceType.DROPDOWN,
          label: option.key,
          key: option.key,
          placeholder: !currentVariant
            ? i18nText.selectPlaceholder
            : option.localizedKey ?? option.key,
          choices: transformOptionChoices(option, selectedVariant),
          localizedKey: option.localizedKey,
        })
        return accumulator
      }, []),
    }
  }
}
